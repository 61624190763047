import React, { useEffect, useMemo, useState } from 'react';

import { CompanyUpdateDTO } from 'types/Company';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/lib/upload';
import {
  convertBase64,
  getValidFileContent,
  toastErrorMessages,
} from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateTenantDetails } from 'api/tenantService';
import { toast } from 'react-toastify';
import { fetchSingleTenant } from 'redux/tenants/actions';
import { SettingTab } from 'types/settings';
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { prefixSelector } from '../EmployeeForm/components/utils';

export default function CompanyProfileForm() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { tenantPersisted: tenant } = useTenantsData();

  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
    type?: string;
  }>({});

  const { t } = useTranslation();

  const BaseUrl = process.env.REACT_APP_URL;
  const openPositionLink = `${BaseUrl}jobs/companies/${tenant?.id}`;
  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = openPositionLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    message.success(t('Open Position Link copied to clipboard'));
  };

  useEffect(() => {
    if (searchParams.get('settings-save') === SettingTab.COMPANYPROFILE) {
      searchParams.delete('settings-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams]);

  const onFinish = (values: CompanyUpdateDTO) => {
    if (tenant?.id) {
      if (Object?.keys(fileToUpload)?.length) {
        values.logoName = fileToUpload.name;
        values.logoContent = fileToUpload.content;
      } else {
        delete values.logoName;
        delete values.logoContent;
      }
      setLoading(true);
      updateTenantDetails(tenant?.id, values)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('companyprofileupdatedSuccessfully'));
            dispatch(fetchSingleTenant(tenant?.id));
          }
        })
        .catch(toastErrorMessages)
        .finally(() => setLoading(false));
    }
  };

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFileToUpload({ name: file.name, content: validBase64, type: file.type });
    return false;
  }

  const CompanyFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.name,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.email,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'sector',
          label: t('industry'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.sector,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'website',
          label: t('website'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.website,
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'country',
          label: t('country'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.country,
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: tenant?.phoneNumber,
          inputProps: {
            type: 'number',
          },
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[3].id,
            placeholder: '',
          },
        },
      ],
      [
        {
          col: 6,
          offset: 0,
          name: 'city',
          label: t('city'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.city,
        },
        {
          col: 6,
          offset: 3,
          name: 'address',
          label: t('address'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.address,
        },
        {
          col: 6,
          offset: 3,
          name: 'zipCode',
          label: t('zipCode'),
          type: InputTypes.INPUT,
          defaultValue: tenant?.zipCode,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('company description'),
          type: InputTypes.TEXTAREA,
          defaultValue: tenant?.description,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'openPositionLink',
          label: (
            <span>
              {t('openPosition')}
              <CopyOutlined
                style={{ marginLeft: '5px', cursor: 'pointer', color: 'blue' }}
                onClick={copyToClipboard}
              />
            </span>
          ),
          type: InputTypes.INPUT,
          defaultValue: openPositionLink,
          inputProps: {
            style: {
              textOverflow: 'ellipsis',
            },
          },
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'logo',
          label: t('logo'),
          type: InputTypes.UPLOAD,
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (file: RcFile) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            defaultFileList: [
              {
                uid: '1',
                name: tenant?.name,
                url: tenant?.logoContent,
              },
            ] as any,
          },
        },
      ],
    ],
    [tenant]
  ) as any;

  return (
    <GenericForm
      formConfiguration={CompanyFormConfiguration}
      onFinish={onFinish}
      form={form}
      loading={loading}
    />
  );
}
